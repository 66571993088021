import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitch
} from "@fortawesome/free-brands-svg-icons";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { COLORS } from "../constants";
import commands from "../data/commands";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px;
  text-align: justify;

  @media (max-width: 1024px) {
    padding: 2rem;
  }

  @media (max-width: 512px) {
    text-align: left;
  }
`;

const TitleWrapper = styled.h1`
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
  }
`;

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="Xabadu" keywords={[`twitch`, `streaming`, `live coding`]} />
      <PageWrapper>
        <TitleWrapper>
          <FontAwesomeIcon
            icon={faTwitch}
            color={COLORS.PURPLE}
            size="sm"
          />
          Stream Commands
        </TitleWrapper>
        {
          commands.map(({ command, value }) => (
            <ul key={command}>
              <li><strong>{command}</strong>: {value}</li>
            </ul>
          ))
        }
      </PageWrapper>
    </Layout>
  );
};

export default AboutPage;
