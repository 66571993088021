export default [
  {
    "command": "!alfajores",
    "value": "Cuales son mis alfajores favoritos (por ahora, cambia todo el tiempo)"
  },
  {
    "command": "!discord",
    "value": "Muestra el link de invitación para nuestro Discord"
  },
  {
    "command": "!editor",
    "value": "Mi editor actual y el tema que estoy usando"
  },
  {
    "command": "!gear",
    "value": "Link para ver el equipamiento que uso para el stream"
  },
  {
    "command": "!github",
    "value": "Mi perfil en GitHub"
  },
  {
    "command": "!hola",
    "value": "Si solo quieres decir hola :)"
  },
  {
    "command": "!hoy",
    "value": "De que se trata el capítulo actual"
  },
  {
    "command": "!lasmanos",
    "value": "Recordatorio para lavarse las manos! 🤚🧼🤚"
  },
  {
    "command": "!proyecto",
    "value": "Proyecto actual en el que estamos trabajando"
  },
  {
    "command": "!qa",
    "value": "Muestra el link del sitio para dejar preguntas para el Q&A"
  },
  {
    "command": "!repo",
    "value": "Repositorio del proyecto actual"
  },
  {
    "command": "!terminal",
    "value": "Mi terminal actual y el tema que estoy usando"
  },
  {
    "command": "!twitter",
    "value": "Mi perfil de Twitter"
  },
  {
    "command": "!url",
    "value": "URL del proyecto actual en producción"
  },
  {
    "command": "!youtube",
    "value": "Mi canal de YouTube"
  },
]